
const Footer = ({ t }: any) => {
 
    return (
        <>
          <td></td>
          <td></td>
        </>
      );
    }
export default (Footer);
